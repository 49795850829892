import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExternalChatbotContainer, ExternalChatbotCustomerMessage, ExternalChatbotFooter, ExternalChatbotHeader, ExternalChatbotHeaderButtonSection, ExternalChatbotHeaderLogo, ExternalChatbotHeaderLogoSection, ExternalChatbotHeaderTitle, ExternalChatbotInput, ExternalChatbotMessage, ExternalChatbotMessageContainer, ExternalChatbotMessagerAvatar, ExternalChatbotMessagesSection, ExternalChatbotMinimizeButton, ExternalChatbotSendButton } from './ExternalChatbot-styling';
import { FaPaperPlane, FaWindowMinimize, FaArrowRotateRight } from 'react-icons/fa6';
import { useQueryClient } from '@tanstack/react-query';
import { ax } from '../Utils';
import { isBrightColor } from '../Functions/isBrightColor';
import ExternalMarkdown from './ExternalMarkdown';
import { useChatbotSettings } from '../Queries/useChatbotSettings';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { Button, Group, Loader, Text, Textarea } from '@mantine/core';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

declare global {
  interface Window {
    zoid: any;
    xprops: any;
  }
}

// Define the type for pending requests
interface PendingRequest {
  config: AxiosRequestConfig;
  resolve: (value: AxiosResponse<any>) => void;
  reject: (error: any) => void;
}

const ZoidExternalChatbot = () => {
  const module = 'external_chatbot';
  // const { messages, error, isLoading, addMessage } = useChat("ChatId");
  const [inputText, setInputText] = useState<string>('');
  const [parentUrl, setParentUrl] = useState<string>('');
  const [refreshTriggered, setRefreshTriggered] = useState<boolean>(false);
  const conversationRef = useRef<HTMLDivElement>(null);


  const [textColor, setTextColor] = useState<string>('#ffffff');

  const [clientKey, setClientKey] = useState<string>('');
  
  const tokenFetched = useRef(false);
  const isRefreshingToken = useRef(false);
  const pendingRequests = useRef<PendingRequest[]>([]);

  //set first assistantChat message to the company greeting

  const queryClient = useQueryClient();

  const { chatbotSettings, updateChatbotSettings } = useChatbotSettings();
  const {assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat, retryLastRequest} = useAssistantChat(module, undefined);
  const { data: answerLoading } = useAnswerLoading(module, undefined);

  // Token refresh function
  const refreshToken = useCallback(async () => {
    if (isRefreshingToken.current) return;
    
    isRefreshingToken.current = true;
    console.log('Refreshing token for embedded chatbot...');
    
    try {
      const response = await axios.post(
        `${ax.defaults.baseURL}/external_chatbot/get_token_and_settings?origin_url=${parentUrl}`, 
        {}, 
        { 
          headers: { 'api-key': clientKey },
          withCredentials: true
        }
      );
      
      if (response.status === 200) {
        tokenFetched.current = true;
        updateChatbotSettings(response.data);
        console.log('Token refreshed successfully');
        
        // Retry all pending requests
        const requests = [...pendingRequests.current];
        pendingRequests.current = [];
        
        for (const request of requests) {
          try {
            const newResponse = await axios.request(request.config);
            request.resolve(newResponse);
          } catch (error) {
            request.reject(error);
          }
        }
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);
      // Reject all pending requests
      pendingRequests.current.forEach(request => 
        request.reject(new Error('Failed to refresh token'))
      );
      pendingRequests.current = [];
    } finally {
      isRefreshingToken.current = false;
    }
  }, [clientKey, parentUrl, updateChatbotSettings]);

  // Setup axios interceptor for token refresh
  useEffect(() => {
    if (!clientKey || !parentUrl) return;
    
    // Create request interceptor to ensure all requests have the latest auth
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        // Only modify chatbot-related requests
        if (config.url && config.url.includes('external_chatbot')) {
          // Ensure we have proper credentials
          config.withCredentials = true;
          
          // If we know the token is expired, refresh it before sending the request
          if (!tokenFetched.current) {
            try {
              await refreshToken();
            } catch (error) {
              console.error('Failed to refresh token before request:', error);
            }
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    
    // Create response interceptor for axios
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (!error.response) return Promise.reject(error);
        
        const originalRequest = error.config;
        if (!originalRequest) return Promise.reject(error);
        
        // Add _retry property to the original request (type assertion)
        const requestWithRetry = originalRequest as AxiosRequestConfig & { _retry?: boolean };
        
        // If error is 401 (Unauthorized) and we haven't tried retrying yet
        // Only intercept requests related to the chatbot
        if (error.response.status === 401 && 
            !requestWithRetry._retry && 
            typeof requestWithRetry.url === 'string' && 
            requestWithRetry.url.includes('external_chatbot')) {
          
          requestWithRetry._retry = true;
          
          if (isRefreshingToken.current) {
            // Wait for token refresh to complete
            return new Promise<any>((resolve, reject) => {
              pendingRequests.current.push({
                config: requestWithRetry,
                resolve,
                reject
              });
            });
          }
          
          // Refresh token and retry request
          try {
            await refreshToken();
            return axios.request(requestWithRetry);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }
        
        return Promise.reject(error);
      }
    );
    
    // Clean up
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [clientKey, parentUrl, refreshToken]);

  const handleMessage = useCallback((event: MessageEvent) => {
    console.log('handleMessage', event.origin, chatbotSettings);
    if (!chatbotSettings?.valid_domains?.includes(event.origin)) {
      // Ignore messages from unknown origins
      return;
    }
    if (event.data && event.data.type === 'toggleOpen') {
      if (assistantChat.length === 1 && assistantChat[0] === '') {
        streamWelcomeMessage(undefined);
      }
    }
  }, [chatbotSettings, assistantChat, streamWelcomeMessage]);
  
  useEffect(() => {
    console.log('ZoidExternalChatbot mounted');

    // Define the Zoid component in the child
    const Chatbot = window.zoid.create({
      tag: 'chatbot-widget',
      props: {
        clientKey: {
          type: 'string',
          required: true,
        },
        parentUrl: {
          type: 'string',
          required: false,
        },
        onLoad: {
          type: 'function',
          required: false,
        },
        // onHide: {
        //   type: 'function',
        //   required: false,
        // },
      },
      //allowedParentDomains: ['https://ragge.ai'],
    });

    // Prevent iframe navigation - blocks redirects to the login page
    const preventNavigation = () => {
      // Store original methods to restore later
      let originalPushState: typeof window.history.pushState | undefined;
      let originalReplaceState: typeof window.history.replaceState | undefined;
      let originalLocationDescriptor: PropertyDescriptor | undefined;
      
      // We only want to intercept navigation events in an iframe context
      if (window.top !== window.self) {
        // This function captures any attempts to change the location
        originalPushState = window.history.pushState;
        originalReplaceState = window.history.replaceState;
        
        window.history.pushState = function(...args) {
          const url = args[2] as string | null;
          
          // Check if it's a redirect to login or authentication page
          if (url && (url.includes('/login') || url.includes('/auth'))) {
            console.log('Preventing navigation to:', url);
            
            // Instead of redirecting, attempt to refresh the token
            if (clientKey && parentUrl) {
              refreshToken().catch(err => console.error('Failed to refresh token during navigation intercept:', err));
            }
            
            // Block the redirect
            return;
          }
          
          // Allow all other navigation
          return originalPushState!.apply(window.history, args);
        };
        
        window.history.replaceState = function(...args) {
          const url = args[2] as string | null;
          
          // Check if it's a redirect to login or authentication page
          if (url && (url.includes('/login') || url.includes('/auth'))) {
            console.log('Preventing navigation to:', url);
            
            // Instead of redirecting, attempt to refresh the token
            if (clientKey && parentUrl) {
              refreshToken().catch(err => console.error('Failed to refresh token during navigation intercept:', err));
            }
            
            // Block the redirect
            return;
          }
          
          // Allow all other navigation
          return originalReplaceState!.apply(window.history, args);
        };
        
        // Also override window.location to prevent direct location changes
        originalLocationDescriptor = Object.getOwnPropertyDescriptor(window, 'location') || 
                                    Object.getOwnPropertyDescriptor(Window.prototype, 'location');
        
        if (originalLocationDescriptor && originalLocationDescriptor.configurable) {
          const newDescriptor = { ...originalLocationDescriptor };
          
          // Override the setter
          const originalSet = originalLocationDescriptor.set;
          if (originalSet) {
            newDescriptor.set = function(url) {
              if (typeof url === 'string' && (url.includes('/login') || url.includes('/auth'))) {
                console.log('Preventing location change to:', url);
                
                // Instead of redirecting, attempt to refresh the token
                if (clientKey && parentUrl) {
                  refreshToken().catch(err => console.error('Failed to refresh token during location change:', err));
                }
                
                // Block the redirect
                return;
              }
              
              // Allow all other location changes
              return originalSet.call(this, url);
            };
          }
          
          try {
            Object.defineProperty(window, 'location', newDescriptor);
          } catch (e) {
            console.error('Failed to override window.location:', e);
          }
        }
      }
      
      // Return function to restore original methods
      return () => {
        if (originalPushState) {
          window.history.pushState = originalPushState;
        }
        
        if (originalReplaceState) {
          window.history.replaceState = originalReplaceState;
        }
        
        if (originalLocationDescriptor && originalLocationDescriptor.configurable) {
          try {
            Object.defineProperty(window, 'location', originalLocationDescriptor);
          } catch (e) {
            console.error('Failed to restore window.location:', e);
          }
        }
      };
    };
    
    // Apply the navigation prevention and store cleanup function
    const restoreNavigation = preventNavigation();

    const xprops = window.xprops || Chatbot.xprops;

    if (xprops) {
      const { clientKey, parentUrl, onLoad } = xprops;
      // console.log('Client Key from xprops:', clientKey);
      // console.log('Parent URL from xprops:', parentUrl);
      setParentUrl(parentUrl);
      setClientKey(clientKey);

      // Notify Zoid that the component has loaded
      if (typeof onLoad === 'function') {
        onLoad();
      } else {
        console.warn('onLoad is not a function');
      }
      let fetchAttempt = 1;
      const MAX_RETRY_ATTEMPTS = 3;

      if (!tokenFetched.current) {
        const fetchTokenAndSettings = async () => {
          try {
            const response = await axios.post(
              `${ax.defaults.baseURL}/external_chatbot/get_token_and_settings?origin_url=${parentUrl}`, 
              {}, 
              { 
                headers: { 'api-key': clientKey },
                withCredentials: true
              }
            );
            
            if (response.status === 200) {
              tokenFetched.current = true;
              window.parent.postMessage({ type: 'TOKEN_FETCHED' }, parentUrl);
              console.log(response.data);
              updateChatbotSettings(response.data);
            }
          } catch (error) {
            console.log('error: ', fetchAttempt);
            if (fetchAttempt < MAX_RETRY_ATTEMPTS) {
              fetchAttempt++;
              // Retry after 2 seconds
              setTimeout(fetchTokenAndSettings, 2000);
            } else {
              // If all retries failed, notify parent to disable the button
              //window.parent.postMessage({ type: 'TOKEN_FETCH_FAILED' }, parentUrl);
              console.log('Token fetch failed');
            }
          }
        };
      
        fetchTokenAndSettings();
      }

    } else {
      console.error('xprops is undefined');
    }
    
    // Clean up everything when component unmounts
    return () => {
      restoreNavigation && restoreNavigation();
      // Add any other cleanup functions here
    };
  }, [refreshToken]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    // Add event listener for link clicks
    const handleLinkClick = (event: MessageEvent) => {
      if (event.data && event.data.type === 'OPEN_LINK') {
        window.parent.postMessage({ 
          type: 'OPEN_LINK', 
          url: event.data.url 
        }, parentUrl);
      }
    };
  
    window.addEventListener('message', handleLinkClick);
  
    return () => {
      window.removeEventListener('message', handleLinkClick);
    };
  }, [parentUrl]);
  
  useEffect(() => {
    if (chatbotSettings?.theme) {
      const isBright = isBrightColor(chatbotSettings.theme);
      setTextColor(isBright ? '#000000' : '#FFFFFF');
    }
  }, [chatbotSettings]);

  useEffect(() => {
    scrollToBottom();
    if (assistantChat[0] === "" && refreshTriggered) {
      setRefreshTriggered(false);
      streamWelcomeMessage(undefined);
    }
  }, [assistantChat]);

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer({text: inputText, module_name: module});
    setInputText('');
  };
  
  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  // const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
  //   setInputText(e.target.value);
  //   e.target.style.height = 'auto';
  //   e.target.style.height = (e.target.scrollHeight - 40 > 200 ? 200 : e.target.scrollHeight - 40) + 'px';
  // };
  
  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMinimize = () => {
    if (window.parent) {
      window.parent.postMessage({ type: 'MINIMIZE_CHATBOT' }, parentUrl); // Send a message to the parent to minimize
    } else {
      console.error('Parent window is not available for messaging');
    }
  };

  const handleRefresh = () => {
    setRefreshTriggered(true);
    clearChat();
    setInputText('');
  }

  return (
    <ExternalChatbotContainer>
      <ExternalChatbotHeader style={{ backgroundColor: chatbotSettings?.theme || '#0f86a1', color: textColor }}>
        <ExternalChatbotHeaderLogoSection>
          <ExternalChatbotHeaderLogo src={chatbotSettings?.assistant_logo_url} />
          {/* alternativt ragge-logo om man inte har någon logo */}
          <ExternalChatbotHeaderTitle>{chatbotSettings?.assistant_name || 'AI-hjälprobot'}</ExternalChatbotHeaderTitle>
        </ExternalChatbotHeaderLogoSection>
        <ExternalChatbotHeaderButtonSection>
          <div style={{ marginTop: '10px', marginRight: '5px' }}>
            <ExternalChatbotMinimizeButton style={{ color: textColor }} onClick={handleRefresh}>
              <FaArrowRotateRight />
            </ExternalChatbotMinimizeButton>
          </div>
          <ExternalChatbotMinimizeButton style={{ color: textColor }} onClick={handleMinimize}>
            <FaWindowMinimize />
          </ExternalChatbotMinimizeButton>
        </ExternalChatbotHeaderButtonSection>
      </ExternalChatbotHeader>
      <ExternalChatbotMessagesSection ref={conversationRef} >
        {/*chat.isLoading ? <p>Loading...</p>
        : chat.isError ? <p>Error: {chat.error.message}</p>
        : chat.data ? chat.data.map((item, index)=> {
            if (index % 2 === 0) { // Check if index is even
              return (
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotMessagerAvatar src="https://via.placeholder.com/30x30" />
                  <ExternalChatbotMessage >
                    {item}
                  </ExternalChatbotMessage>
                </ExternalChatbotMessageContainer>
              )
            } else {
              return(
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotCustomerMessage>
                    {item}
                  </ExternalChatbotCustomerMessage>
                </ExternalChatbotMessageContainer>
              )
            }
          }) : null
      */}
      {assistantChat.map((item, index) => {
        if (index % 2 === 0) { // Check if index is even
          return (
            <ExternalChatbotMessageContainer key={index}>
              <ExternalChatbotMessagerAvatar src={chatbotSettings?.assistant_logo_url} />
              {/* alternativt ragge-logo om man inte har någon logo */}
              <ExternalChatbotMessage >
                <ExternalMarkdown markdownText={item} textColor='#000' />
                {!item 
                ? answerLoading?.[index/2] 
                  ? <Loader type="dots" size="xs" /> 
                  : <Group gap={0}>
                      <Text size="sm">Någonting gick fel.</Text>
                      <Button
                        variant="subtle"
                        onClick={() => retryLastRequest()}
                        size="sm"
                        color="blue"
                        p={5}
                      >
                        Klicka här för att försöka igen
                      </Button>
                    </Group>
                : null}
              </ExternalChatbotMessage>
            </ExternalChatbotMessageContainer>
          )
        } else {
          return(
            <ExternalChatbotMessageContainer key={index}>
              <ExternalChatbotCustomerMessage>
                <ExternalMarkdown markdownText={item} textColor='#fff' />
              </ExternalChatbotCustomerMessage>
            </ExternalChatbotMessageContainer>
          )
        }
      })}


      </ExternalChatbotMessagesSection>
      <ExternalChatbotFooter>
        <Textarea
          placeholder="Skriv ett meddelande..."
          minRows={1}
          maxRows={5}
          onKeyDown={handleEnterSend}
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          w="100%"
          radius="md"
          styles={{
            input: {
              fontSize: "1rem",
              lineHeight: "1.25",
              padding: "10px",
              paddingRight: "35px",
            }
          }}
          rightSectionWidth={35}
          rightSection={
            <ExternalChatbotSendButton onClick={handleSend} style={{ backgroundColor: chatbotSettings?.theme || '#0f86a1', color: textColor }}>
              <FaPaperPlane />
            </ExternalChatbotSendButton>
          }
          />
      </ExternalChatbotFooter>
    </ExternalChatbotContainer>
  );
}

export default ZoidExternalChatbot;