import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { 
  FeedbackChatSection, 
  MessagesSection, 
  SeparatorLine, 
  MessageTitle, 
  EmailContainer, 
  EmailToFrom, 
  EmailInfoText, 
  EmailSender,
  EmailContent,
  ToolsSeparatorLine,
  SendEmailButton,
  Review,
  ReviewResponseInput,
  ReviewRating,
  ReviewText,
  MessageContainer,
  MessageBubble,
  MessageInfoContainer,
} from "../Feedback-styling";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image'
import { MantineProvider, Loader, Center, Group, Badge, Button, Tooltip, Text } from '@mantine/core';
import { ToolsGroup } from '../../MarketingPage/MarketingPage-styling';
import {FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBan, FaBellSlash, FaBold, 
  FaCheck, 
  FaFont, FaHeading, FaHighlighter, FaItalic, FaLink, FaListOl, FaListUl, 
  FaRedo, FaStrikethrough, FaTrash, FaUnderline, FaUndo, FaUnlink
} from "react-icons/fa";
import { Feedback, FeedbackType, Message, Email, FeedbackDataMap, FeedbackThread } from '../../Types';
import { NoActiveErrandSection, NoActiveErrandText } from '../../ErrandsPage/ErrandsPage-styling';
import EmailTagsInput from '../../Components/EmailTagsInput';
import { useEmailDraftMutation } from '../../Mutations/EmailDraftMutation';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';
import MarkdownRenderer from '../../Components/MarkdownRenderer';
import { ax } from '../../Utils';
import { queryClient } from '../../lib/queryClient';
import { toast } from 'react-toastify';

// Helper function to get badge color based on status
const getStatusColor = (status: string): string => {
  switch (status) {
    case 'local_draft':
      return 'yellow'; // Draft saved locally
    case 'provider_draft':
      return 'cyan'; // Draft from provider
    case 'sent':
      return 'green'; // Successfully sent
    case 'received':
      return 'blue'; // Received message
    default:
      return 'gray'; // Default color
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case 'local_draft':
      return 'local draft';
    case 'provider_draft':
      return 'draft in provider';
    default:
      return status;
  }
};

type FeedbackProps = {
  feedbackThread: FeedbackThread | undefined;
  timePassed: (date: Date) => string;
  handleChange: (html: string, text: string, draft: Email | null, selectedFeedback: Feedback | null) => void;
  cancelHandleChange: () => void;
  content: string;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  sendEmail: (html: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
  emailAddresses: {to: string[], cc: string[], bcc: string[]} | null;
  draft: Email | null;
};

const FeedbackChat = (props: FeedbackProps) => {
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();

  const handleMarkAsHandled = () => {
    console.log('Mark as handled');
    try {
      ax.patch(`/feedback/change_status/?feedback_id=${selectedFeedback?.id}&is_closed=true`)
      .then(() => {
        updateSelectedFeedback(null);
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.filter(feedback => feedback.id !== selectedFeedback?.id);
        });
      });
    }
    catch (error) {
      toast.error('Något gick fel, försök igen senare.');
    }
  }

  const handleMarkAsIrrelevant = () => {
    console.log('Mark as irrelevant');
    try {
      ax.delete(`/feedback/${selectedFeedback?.id}`)
      .then(() => {
        updateSelectedFeedback(null);
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.filter(feedback => feedback.id !== selectedFeedback?.id);
        });
      });
    }
    catch (error) {
      toast.error('Något gick fel, försök igen senare.');
    }
  }

  const handleIgnoreSender = () => {
    console.log('Ignore sender');
    try {
      if (props.feedbackThread?.feedback_type === 'email') {
        ax.patch(`/email_source/ignore_email_address?email_source_id=${selectedFeedback?.email_source_id}&email_address_to_ignore=${props.feedbackThread.data[0].from_attendee.identifier}`)
        .then(() => {
          updateSelectedFeedback(null);
          queryClient.invalidateQueries({ queryKey: ['feedback', 'all', 'list'] });
        });
      }
    }
    catch (error) {
      toast.error('Något gick fel, försök igen senare.');
    }
  }


  if (!props.feedbackThread) {
    return (
      <FeedbackChatSection>
        <NoActiveErrandSection style={{backgroundColor: 'transparent'}}><NoActiveErrandText>Välj en Feedback</NoActiveErrandText></NoActiveErrandSection>
      </FeedbackChatSection>
    );
  }

  const {feedback_type, data: MessageList} = props.feedbackThread;

  return (
    <FeedbackChatSection>
      {feedback_type === 'email' ? 
        <>
          <MessagesSection>
            <Group justify="space-between" mt="xs" ml="xs" mr="xs">
              <Group>
                {props.feedbackThread.data[props.feedbackThread.data.length - 1]?.status &&
                  <Group gap="5">
                    <Text fz="sm" c="#333" lh="1.2" mb="-5px">
                      Status:
                    </Text>
                    <Badge color={getStatusColor(props.feedbackThread.data[props.feedbackThread.data.length - 1].status)} mr="xs">
                      {getStatusText(props.feedbackThread.data[props.feedbackThread.data.length - 1]?.status)}
                    </Badge>
                  </Group>
                }
              </Group>
              <Group gap="3">
                <Tooltip label="Tar bort mailtråden från inkorgen, men sparar kunskapen för att lära sig till nästa gång.">
                  <Button h={20} size="xs" variant="subtle"
                    styles={{
                      root: {
                        padding: '1px 5px'
                      }
                    }}
                    onClick={handleMarkAsHandled}
                    leftSection={
                      <div style={{ marginRight: '-4px' }}>
                        <FaCheck size={16}/>
                      </div>
                    }
                  >
                    Avklarat
                  </Button>
                </Tooltip>
                <Tooltip label="Raderar mailtråden från ragge.ai.">
                  <Button h={20} size="xs" variant="subtle"
                    styles={{
                      root: {
                        padding: '1px 5px'
                      }
                    }}
                    onClick={handleMarkAsIrrelevant}
                    leftSection={
                      <div style={{ marginRight: '-4px' }}>
                        <FaBan size={16}/>
                      </div>
                    }
                  >
                    Irrelevant
                  </Button>
                </Tooltip>
                <Tooltip label="Raderar mailtråden från ragge.ai och ignorerar alla framtida meddelanden från avsändaren.">
                  <Button h={20} size="xs" variant="subtle"
                    styles={{
                      root: {
                        padding: '1px 5px'
                      }
                    }}
                    onClick={handleIgnoreSender}
                    leftSection={
                      <div style={{ marginRight: '-4px' }}>
                        <FaBellSlash size={16}/>
                      </div>
                    }
                  >
                    Ignorera
                  </Button>
                </Tooltip>
              </Group>
            </Group>
            <MessageTitle>
              {selectedFeedback?.title || selectedFeedback?.feedback_source_type_name || "Feedback"}
            </MessageTitle>
            <SeparatorLine />
            {MessageList && MessageList.length > 0 ?
              MessageList.map((item, index) => {
                if (!item.sent_at || !item.from_attendee) return null;
                return (
                  <Fragment key={index}>
                  <EmailContainer>
                    <EmailToFrom>
                      {item.from_attendee.display_name ?
                        <EmailInfoText>
                          <EmailSender>{item.from_attendee.display_name}</EmailSender> 
                          &nbsp;&lt;{item.from_attendee.identifier}&gt; 
                          &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan<br />
                          till {item.to_attendees.concat(item.cc_attendees).map((receiver, _recIndex, array) => {
                            if (receiver) {
                              const name = receiver.display_name || receiver.identifier;
                              const isLast = _recIndex === array.length - 1;
                              return (
                                <Fragment key={_recIndex}>
                                  {name}{!isLast && ', '}
                                </Fragment>
                              );
                            }
                            return null;
                            })}
                        </EmailInfoText>
                      : <EmailInfoText>
                          <EmailSender>{item.from_attendee.identifier}</EmailSender>
                          &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan<br />
                          till {item.to_attendees.concat(item.cc_attendees).map((receiver, _recIndex, array) => {
                            const name = receiver.display_name || receiver.identifier;
                            const isLast = _recIndex === array.length - 1;
                            return (
                              <Fragment key={_recIndex}>
                                {name}{!isLast && ', '}
                              </Fragment>
                            );
                          })}
                        </EmailInfoText>
                      }
                    </EmailToFrom>
                    <EmailContent dangerouslySetInnerHTML={{__html: item.body }} />
                  </EmailContainer>
                  <SeparatorLine />
                </Fragment>
              );
              })
            : <></>}
          </MessagesSection>
          <EmailEditor
            handleChange={props.handleChange}
            cancelHandleChange={props.cancelHandleChange}
            selectedFeedback={selectedFeedback}
            content={props.content}
            selectedFeedbackRef={props.selectedFeedbackRef}
            sendEmail={props.sendEmail}
            editorRef={props.editorRef}
            emailAddresses={props.emailAddresses}
            feedbackThread={props.feedbackThread}
            draft={props.draft}
          />
        </>
      // : feedback_type === 'review' ? 
      //   <>
      //     <MessagesSection>
      //       <MessageTitle>
      //         {props.selectedFeedback?.title || props.selectedFeedback?.feedback_source_type_name || "Feedback"}
      //       </MessageTitle>
      //       <SeparatorLine />
      //       {MessageList && MessageList.length > 0 ?
      //         MessageList.map((item, index) => 
      //           <Review>
      //             <EmailToFrom>
      //               <EmailInfoText>
      //                 <EmailSender>{item.sender_contact_info.name}</EmailSender> 
      //                 &nbsp;&bull;&nbsp; Recension från Google<br />
      //                 {/* <ReviewRating>{props.selectedFeedback?.rating}/5</ReviewRating>  */}
      //                 &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan
      //               </EmailInfoText>
      //             </EmailToFrom>
      //             <ReviewText>{item.text}</ReviewText>
      //           </Review>
      //         )
      //       : <></>}
      //     </MessagesSection>
      //     <ReviewResponseInput
      //       placeholder="Svara här"
      //       rows={1}
      //     />
      //   </>
      : feedback_type === 'external_chatbot' ?
        <>
          <MessagesSection>
            <MessageTitle>Chatbot</MessageTitle>
            <SeparatorLine />
            {MessageList && MessageList.length > 0 ?
              MessageList.map((message, index) => {
                if (message.user_role === 'assistant') {
                  return (
                    <MessageContainer $isCustomer={false} key={index}>
                      <MessageBubble $isCustomer={false}>
                        <MarkdownRenderer markdownText={message.content} isStreaming={false} fontSize={16} />
                        <MessageInfoContainer>
                          <EmailInfoText>
                            AI-bot
                            &nbsp;&bull;&nbsp; {props.timePassed(new Date(message.created_at))} sedan
                          </EmailInfoText>
                        </MessageInfoContainer>
                      </MessageBubble>
                    </MessageContainer>
                  );
                }
                return (
                  <MessageContainer $isCustomer={true} key={index}>
                    <MessageBubble $isCustomer={true}>
                      <MarkdownRenderer markdownText={message.content} isStreaming={false} fontSize={16} />
                      <MessageInfoContainer>
                        <EmailInfoText>
                          Kund
                          &nbsp;&bull;&nbsp; {props.timePassed(new Date(message.created_at))} sedan
                        </EmailInfoText>
                      </MessageInfoContainer>
                    </MessageBubble>
                  </MessageContainer>
                );
              })
            : 
              <Center style={{ height: '70vh' }}>
                <Loader color="#fff" size="xl" />
              </Center>
            }
          </MessagesSection>
        </>
      : <NoActiveErrandSection style={{backgroundColor: 'transparent'}}><NoActiveErrandText>Välj en Feedback</NoActiveErrandText></NoActiveErrandSection>}
    </FeedbackChatSection>
  );
};
export default FeedbackChat;


type Props = {
  handleChange: (html: string, text: string, draft: Email | null, selectedFeedback: Feedback | null) => void;
  cancelHandleChange: () => void;
  selectedFeedback: Feedback | null;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  content: string;
  sendEmail: (html: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
  emailAddresses: {to: string[], cc: string[], bcc: string[]} | null;
  feedbackThread: FeedbackThread | null;
  draft: Email | null;
};

const EmailEditor = (props: Props) => {
  const [showTools, setShowTools] = useState(false);
  const [showEmailTags, setShowEmailTags] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const emails = props.emailAddresses ? (props.emailAddresses.bcc.length > 0 
    ? props.emailAddresses.to.concat(props.emailAddresses.cc).join(', ') 
    + `, Hemlig kopia: ${props.emailAddresses.bcc.join(', ')}` 
    : props.emailAddresses.to.concat(props.emailAddresses.cc).join(', ')) 
    : null;
  const toInputRef = useRef<HTMLInputElement>(null);
  const ccInputRef = useRef<HTMLInputElement>(null);
  const bccInputRef = useRef<HTMLInputElement>(null);

  const { selectedFeedback } = useSelectedFeedback();
  //const { data: draft } = useDraft(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  const emailDraftMutation = useEmailDraftMutation();
  //const { data: feedbackData } = useFeedbackData("email", selectedFeedback?.id);

  const { feedback_type, data: feedbackData } = props.feedbackThread || {};

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
    ],
    content: props.content,
    onUpdate: ({ editor }) => {
      if(props.selectedFeedbackRef.current === props.selectedFeedback) {
        props.cancelHandleChange(); // Cancel the debounced function
      }
      else {
        props.selectedFeedbackRef.current = props.selectedFeedback;
      }
      props.handleChange(editor.getHTML(), editor.getText(), props.draft, props.selectedFeedback);
    }
  });

  useEffect(() => {
    if (props.editorRef.current) {
      props.editorRef.current.commands.setContent(props.content.replace(/\n/g, '<br>'));
    }
  }, [props.content])

  useEffect(() => {
    props.editorRef.current = editor;
    const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
    // const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
    console.log("editor", editor?.getHTML());
    // console.log("greatGrandparentElement", greatGrandparentElement?.clientHeight);

    if (proseMirrorElement){
      proseMirrorElement.style.outline = 'none';
      proseMirrorElement.style.minHeight = '9rem';
    }
    // const handleResize = () => {
    //   if (proseMirrorElement && greatGrandparentElement) {
    //     const greatGrandparentHeight = greatGrandparentElement.clientHeight;
    //     const heightPercentage = 100; // Adjust the percentage as needed

    //     if (showTools){
    //       proseMirrorElement.style.height = `${(heightPercentage / 100) * greatGrandparentHeight - 117}px`;
    //     }
    //     else {
    //       proseMirrorElement.style.height = `${(heightPercentage / 100) * greatGrandparentHeight - 72}px`;
    //     }
    //   }
    // };

    // // Initial setting of minHeight
    // handleResize();

    // // Listen for window resize event
    // window.addEventListener('resize', handleResize);

    // // Cleanup event listener on component unmount
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, [editor]);

  useEffect(() => {
    console.log("draft", props.draft);
    if (selectedFeedback && feedbackData && feedback_type === 'email' && !props.draft?.id && !emailDraftMutation.isPending) {
      const sourceEmail = feedbackData[0]?.feedback_source_email;
      const sourceEmailBase = feedbackData[0]?.feedback_source_email_base;

      console.log("inside ", feedbackData);

      const lastEmail = feedbackData[feedbackData.length - 1];

      if (!lastEmail) {
        // Handle the case where emails array is empty
        return;
      }

      const filterEmails = (attendees: { display_name: string, identifier: string }[] = []) => {
        return attendees.map(a => a.identifier).filter(email => email !== sourceEmail && email !== sourceEmailBase);
      }

      const fromAttendee = lastEmail.from_attendee;

      const requestBody = {
        feedback_id: selectedFeedback.id,
        body: editor?.getHTML() ?? '',
        to_attendees: Array.from(new Set(filterEmails(lastEmail.to_attendees.concat(fromAttendee) || []))).map(identifier => ({ identifier })),
        cc_attendees: Array.from(new Set(filterEmails(lastEmail.cc_attendees || []))).map(identifier => ({ identifier })),
        bcc_attendees: Array.from(new Set(filterEmails(lastEmail.bcc_attendees || []))).map(identifier => ({ identifier }))
      };
      emailDraftMutation.mutate(requestBody, {
      });
    }
  }, [selectedFeedback?.id, feedbackData])


  useEffect(() => {
    setShowEmailTags(false)
    setShowCc(false)
    setShowBcc(false)
  }, [selectedFeedback])

  useEffect(() => {
    if (showEmailTags && toInputRef.current) {
      toInputRef.current.focus();
    }
  }, [showEmailTags]);

  useEffect(() => {
    if (showCc && ccInputRef.current) {
      ccInputRef.current.focus();
    }
  }, [showCc]);

  useEffect(() => {
    if (showBcc && bccInputRef.current) {
      bccInputRef.current.focus();
    }
  }, [showBcc]);

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor]);

  const handleEmailsChange = (emails: string[], recipientType: string) => {
    if (selectedFeedback) {
      console.log(props.draft);
      if (props.draft && props.draft.id) {
        emailDraftMutation.mutate({
          id: props.draft.id,
          feedback_id: selectedFeedback.id,
          [recipientType]: emails.map((email) => ({identifier: email})),
          body: editor?.getHTML() ?? '',
        }
      );
      }
      else {
        emailDraftMutation.mutate({
          feedback_id: selectedFeedback.id,
          [recipientType]: emails,
          body: editor?.getHTML() ?? '',
        },
        {
          onSuccess: () => {
            // Handle success if needed
          }
        });
      }
    }
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
      width: 'calc(100% - 20px)',
      border: 'solid 2px #444',
      borderRadius: '20px',
      alignSelf: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      margin: '0 0 5px 0',
      overflow: 'hidden',
      height: 'auto',
      maxHeight: '100%',
      minHeight: '250px',
    }}
    >
      {showEmailTags && props.emailAddresses ?
        <EmailTagsInput
          emailAddresses={props.emailAddresses}
          showCc={showCc}
          showBcc={showBcc}
          setShowCc={setShowCc}
          setShowBcc={setShowBcc}
          toInputRef={toInputRef}
          ccInputRef={ccInputRef}
          bccInputRef={bccInputRef}
          handleEmailsChange={handleEmailsChange}
        />
      : <div style={{padding: '10px 10px 0px 10px', color: '#ddd'}}
          onClick={() => {
            setShowEmailTags(true);
          }}
        >{emails ? emails : 'Mottagare'}</div>
      }
      <MantineProvider>
        <RichTextEditor 
          editor={editor}
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '300px',
            height: 'auto',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >

          <RichTextEditor.Content style={{
              flexGrow: 0,
              height: 'auto',
              overflowY: 'auto',
              color: '#fff',
              padding: '0 10px',
              border: 'none',
            }}
            onClick={() => {
              setShowEmailTags(false)
              setShowCc(false)
              setShowBcc(false)
            }}
          />
          {showTools &&
            <RichTextEditor.Toolbar style={{ display: 'flex', boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.4)', maxWidth: 'fit-content', margin: '5px 10px 10px 10px'}}>
              <ToolsGroup>
                <button 
                  onClick={() => editor?.chain().focus().undo().run()}
                  disabled={!editor?.can().undo()}
                  className={editor?.can().undo() ? '' : 'is-disabled'}
                >
                  <FaUndo/>
                </button>
                <button 
                  onClick={() => editor?.chain().focus().redo().run()} 
                  disabled={!editor?.can().redo()}
                  className={editor?.can().redo() ? '' : 'is-disabled'}
                >
                  <FaRedo/>
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                  className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                  <FaHeading />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleBold().run()}
                  className={editor?.isActive('bold') ? 'is-active' : ''}
                >
                  <FaBold />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleItalic().run()}
                  className={editor?.isActive('italic') ? 'is-active' : ''}
                >
                  <FaItalic />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleUnderline().run()}
                  className={editor?.isActive('underline') ? 'is-active' : ''}
                >
                  <FaUnderline />
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleBulletList().run()}
                  className={editor?.isActive('bulletList') ? 'is-active' : ''}
                >
                  <FaListUl />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                  className={editor?.isActive('orderedList') ? 'is-active' : ''}
                >
                  <FaListOl />
                </button>

                <button
                  onClick={() => editor?.chain().focus().setTextAlign('left').run()}
                  className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
                >
                  <FaAlignLeft />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('center').run()}
                  className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
                >
                  <FaAlignCenter />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
                  className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
                >
                  <FaAlignJustify />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('right').run()}
                  className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
                >
                  <FaAlignRight />
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleStrike().run()}
                  className={editor?.isActive('strike') ? 'is-active' : ''}
                >
                  <FaStrikethrough />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleHighlight().run()}
                  className={editor?.isActive('highlight') ? 'is-active' : ''}
                >
                  <FaHighlighter />
                </button>
              </ToolsGroup>

            </RichTextEditor.Toolbar>
          }
          <RichTextEditor.Toolbar style={{ display: 'flex', margin: '5px 10px 10px 10px'}}>
            <SendEmailButton onClick={() => props.sendEmail(editor?.getHTML() ?? '')}>Skicka</SendEmailButton>
            <ToolsGroup style={{alignSelf: 'center'}}>
              <button
                onClick={() => setShowTools(!showTools)}
                className={showTools ? 'is-active' : ''}
              >
                <FaFont/>
              </button>
              <button 
                onClick={setLink}
                className={editor?.isActive('link') ? 'is-active' : ''}
                >
                <FaLink/>
              </button>
              <button
                onClick={() => editor?.chain().focus().unsetLink().run()}
                disabled={!editor?.isActive('link')}
                className={!editor?.isActive('link') ? 'is-disabled' : ''}
              >
                <FaUnlink/>
              </button>
            </ToolsGroup>

          </RichTextEditor.Toolbar>

        </RichTextEditor>
      </MantineProvider>
    </div>
  );
};