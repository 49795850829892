import {
  ErrandsListSection,
  ErrandsHeader,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Feedback, FeedbackType } from '../../Types';
import { formatDateTime } from '../../Functions/formatDateTime';
import { useFeedbackList } from '../../Queries/useFeedbackList';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';
import { FeedbackListSearch, FeedbackListSearchContainer, FeedbackListSearchIconButton, FeedbackSelectHeader } from "../Feedback-styling";
import { FaSearch, FaEllipsisV, FaTrash, FaBan, FaBellSlash, FaCheck } from "react-icons/fa";
import { useFeedbackTypeFilter } from "../../Queries/useFeedbackTypeFilter";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { queryClient } from "../../lib/queryClient";
import { ActionIcon, Group, Menu, Tooltip } from "@mantine/core";
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md";
import { ax } from "../../Utils";
import { toast } from "react-toastify";

type FeedbackCardProps = {
  feedback: Feedback;
  lastClickedId: string | null;
};

const FeedbackCard = (props: FeedbackCardProps) => {
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();
  const isUnread = !props.feedback.last_opened_at;
  const [buttonInteracting, setButtonInteracting] = useState(false);
  
  // Prevent menu click from opening the feedback
  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleMarkAsRead = async (e: React.MouseEvent) => {
    console.log("Mark as read");
    e.stopPropagation();
    try {
      await ax.patch(`/feedback`, {
        id: props.feedback.id,
        last_opened_at: new Date()
      }).then(() => {
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.map(feedback => ({
            ...feedback,
            last_opened_at: feedback.id === props.feedback.id ? new Date() : feedback.last_opened_at
          }));
        });
      });
    } catch (error) {
      toast.error("Kunde inte markera som läst, försök igen senare");
    }
  };

  const handleMarkAsUnread = async (e: React.MouseEvent) => {
    e.stopPropagation();
    console.log("Mark as unread");
    try {
      await ax.patch(`/feedback`, {
        id: props.feedback.id,
        last_opened_at: null
      }).then(() => {
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.map(feedback => ({
            ...feedback,
            last_opened_at: feedback.id === props.feedback.id ? null : feedback.last_opened_at
          }));
        });
      });
    } catch (error) {
      toast.error("Kunde inte markera som oläst, försök igen senare");
    }
  };

  const handleMarkAsHandled = () => {
    console.log('Mark as handled');
    try {
      ax.patch(`/feedback/change_status/?feedback_id=${selectedFeedback?.id}&is_closed=true`)
      .then(() => {
        updateSelectedFeedback(null);
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.filter(feedback => feedback.id !== selectedFeedback?.id);
        });
      });
    }
    catch (error) {
      toast.error('Något gick fel, försök igen senare.');
    }
  }

  const handleMarkAsIrrelevant = () => {
    console.log('Mark as irrelevant');
    try {
      ax.delete(`/feedback/${selectedFeedback?.id}`)
      .then(() => {
        updateSelectedFeedback(null);
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.filter(feedback => feedback.id !== selectedFeedback?.id);
        });
      });
    }
    catch (error) {
      toast.error('Något gick fel, försök igen senare.');
    }
  }

  const handleIgnoreSender = () => {
    console.log('Ignore sender');
  }


  return (
    <ErrandCardStyling 
      onClick={() => {
        updateSelectedFeedback(props.feedback);
        queryClient.setQueryData(['feedback', 'all', 'list'], (oldData: Feedback[]) => {
          return oldData.map(feedback => ({
            ...feedback,
            last_opened_at: feedback.id === props.feedback.id ? new Date() : feedback.last_opened_at
          }));
        });
      }}
      selected={props.lastClickedId === props.feedback.id}
      isUnread={isUnread}
      buttonInteracting={buttonInteracting}
    >
      {isUnread && 
        <div 
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            width: '6px',
            backgroundColor: 'var(--mantine-color-blue-5)'
          }}
        />
      }
      <Group gap={0} justify="space-between" align="start" wrap="nowrap">
        <ErrandCardTitle
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '0'
          }}
        >
          {props.feedback.title}
        </ErrandCardTitle>
        
        <Menu position="bottom-start" withinPortal>
          <Menu.Target>
            <ActionIcon 
              size="sm"
              variant="subtle" 
              onClick={handleMenuClick}
              style={{ color: '#bbb' }}
              onMouseEnter={() => setButtonInteracting(true)}
              onMouseLeave={() => setButtonInteracting(false)}
              onMouseDown={() => setButtonInteracting(true)}
            >
              <FaEllipsisV size={14} />
            </ActionIcon>
          </Menu.Target>
          
          <Menu.Dropdown>
            <Tooltip label="Tar bort mailtråden från inkorgen, men sparar kunskapen för att lära sig till nästa gång." position="right">
              <Menu.Item
                leftSection={<FaCheck size={14} />}
                onClick={handleMarkAsHandled}
                color="green"
            >
                Avklarat
              </Menu.Item>
            </Tooltip>
            <Tooltip label="Raderar mailtråden från ragge.ai." position="right">
              <Menu.Item 
                leftSection={<FaTrash size={14} />}
                onClick={handleMarkAsIrrelevant}
                color="red"
              >
                Irrelevant
              </Menu.Item>
            </Tooltip>
            <Tooltip label={isUnread ? "Markera som läst." : "Markera som oläst."} position="right">
              <Menu.Item 
                leftSection={isUnread ? <MdOutlineMarkEmailRead size={16} style={{ marginLeft: '-2px' }} /> : <MdOutlineMarkEmailUnread size={16} style={{ marginLeft: '-2px' }} />}
                onClick={isUnread ? handleMarkAsRead : handleMarkAsUnread}
              >
                {isUnread ? 'Markera som läst' : 'Markera som oläst'}
              </Menu.Item>
            </Tooltip>
            <Tooltip label="Raderar alla mailtrådar från avsändaren och ignorerar alla framtida mail från avsändaren." position="right">
              <Menu.Item 
                leftSection={<FaBellSlash size={14} />}
                onClick={handleIgnoreSender}
                color="#555"
            >
                Ignorera avsändare
              </Menu.Item>
            </Tooltip>
          </Menu.Dropdown>
        </Menu>
      </Group>
      
      <ErrandCardDate>
        {formatDateTime(props.feedback.last_message_sent_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  lastClickedId: string | null;
};

const FeedbackList = (props: Props) => {
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { feedbackTypeFilter, updateFeedbackTypeFilter } = useFeedbackTypeFilter();
  const { data: feedbacks, ...feedbacksQuery } = useFeedbackList(feedbackTypeFilter || "", searchText);
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && (!selectedFeedback || selectedFeedback.id !== id)) {
      // Find feedback with matching ID from the list
      const feedback = feedbacks?.find(f => f.id === id);
      if (feedback) {
        updateSelectedFeedback(feedback);
      }
    }
  }, [id]);

  useEffect(() => {
    if (selectedFeedback && selectedFeedback.id !== id) {
      navigate(`/feedback/${selectedFeedback.id}`);
    } else if (!selectedFeedback && id) {
      navigate('/feedback');
    }
  }, [selectedFeedback]);

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearchText(event.currentTarget.value);
      console.log(searchText);
    }
  };

  useEffect(() => {
    feedbacksQuery.refetch(); //TODO: KANSKE refetcha inte på feedback type, fixa filtrering i frontend för att minska antal requests
  }, [feedbackTypeFilter, searchText]); //ej viktigt

  return (
    <ErrandsListSection>
      <FeedbackSelectHeader 
        value={feedbackTypeFilter || ""}
        onChange={(e: { target: { value: string }; }) => {
          updateFeedbackTypeFilter(e.target.value as FeedbackType);
        }}
      >
        <option value="">All feedback</option>
        <option value="email">E-post</option>
        <option value="review">Recensioner</option>
        <option value="external_chatbot">Kundchatbot</option>
      </FeedbackSelectHeader>
      <FeedbackListSearchContainer>
        <FeedbackListSearchIconButton
          onClick={() => {
            if (searchInputRef.current) {
              setSearchText(searchInputRef.current.value);
            }
          }}
        >
          <FaSearch />
        </FeedbackListSearchIconButton>
        <FeedbackListSearch 
          ref={searchInputRef}
          placeholder="Sök..."
          onKeyDown={handleEnterSearch}
        />

      </FeedbackListSearchContainer>
      <ErrandsListContainer>
        {feedbacks?.map((feedback, index) => (
          <FeedbackCard
            key={index}
            feedback={feedback}
            lastClickedId={props.lastClickedId}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default FeedbackList;

